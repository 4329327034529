export function utcDate(yyyymmdd?: string) {
  const date = yyyymmdd ? new Date(yyyymmdd) : new Date();
  return (new Date(date.getTime() - date.getTimezoneOffset() * 60000))
}

export function formatDate(dateString: string): string {
  const [year, month, day] = dateString.split('-').map(Number);
  const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return `${months[month]} ${day}, ${year}`;
}

export function formatTimeSince(dateString: string): string {
  const date = utcDate(dateString);
  const now = utcDate();
  const secondsPast = (now.getTime() - date.getTime()) / 1000;

  if (secondsPast < 60) {
    return `${Math.round(secondsPast)} sec ago`;
  } else if (secondsPast < 3600) {
    return `${Math.round(secondsPast / 60)} min ago`;
  } else if (secondsPast <= 86400) {
    return `${Math.round(secondsPast / 3600)} hr ago`;
  } else if (secondsPast > 86400) {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    let hour = date.getHours();
    const min = date.getMinutes();
    const ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12;
    hour = hour ? hour : 12;
    const minStr = min < 10 ? '0' + min : min;
    return `${month} ${day}, ${year} at ${hour}:${minStr} ${ampm}`;
  }
  return "Invalid date."
}

export const formatBTC = (sats: string | number) => {

  if (typeof sats === 'string') {
    sats = parseInt(sats.replace(/,/g, ''), 10);
  }
  return !isNaN(sats) ? (sats / 100000000).toString() : '0.00000000';
};